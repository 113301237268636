import { TFunction } from "i18next"

enum SimpleBooleanEnum {
  YES = 'YES',
  NO = 'NO',
}

export class SimpleBooleanEnumHelper {

    static format = (
        response: SimpleBooleanEnum,
        t: TFunction,
      ): string => {
        return t(`basicResponse.${response}`) || response
      }


  static allKinds = (): SimpleBooleanEnum[] => Object.values(SimpleBooleanEnum)

  static mapper = (value: SimpleBooleanEnum) :boolean => {
    switch (value) {
      case SimpleBooleanEnum.NO:
        return false
      case SimpleBooleanEnum.YES:
        return true
    }
  }
}

export default SimpleBooleanEnum
