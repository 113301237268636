import React from 'react'
import { 
  IwmFlagCh,
  IwmFlagEs,
  IwmFlagFr,
  IwmFlagGb,
  IwmFlagHk,
  IwmFlagIt,
  IwmFlagLu,
  IwmFlagMc,
  IwmFlagSg
} from '@iwm-ds/react'
import CountryEnum from '../domain/country.enum'

interface Props {
  size?: number
  className?: string
  country: CountryEnum
}

const CountryIcon = ({ size = 20, className, country }: Props): JSX.Element => {
  
  const IconMapper = (country: CountryEnum) => {

    const props = {
      alt:"country flag",
      height:{size},
      width:{size},
      className:className
    }


    switch (country) {
      case CountryEnum.FRANCE:
        return <IwmFlagFr {...props} />
      
      case CountryEnum.ITALIE:
        return <IwmFlagIt {...props} />

      case CountryEnum.ESPAGNE:
        return <IwmFlagEs {...props} />

      case CountryEnum.HONG_KONG:
        return <IwmFlagHk {...props} />
    
      case CountryEnum.LUXEMBOURG:
        return <IwmFlagLu {...props} />
      
      case CountryEnum.SUISSE:
        return <IwmFlagCh {...props} />

      case CountryEnum.UK:
        return <IwmFlagGb {...props} />

      case CountryEnum.SINGAPOUR:
        return <IwmFlagSg {...props} />
      
      case CountryEnum.MONACO:
        return <IwmFlagMc {...props} />
      
      default:
        break;
    }
  }

  return IconMapper(country) ?? <></>
}

export default CountryIcon
